
table{
    padding: 0 5rem;
    color: #fff;
    margin-bottom: 70px;
}

tr,caption{
    text-align: center;
}
table,th,td{
    border: 1px solid #fff;
    border-collapse: collapse;
}
th{
    padding: 10px;
}
tr,td{
    padding: 20px;
}
tr>:nth-child(even){
    background-color: var(--gray);
}
tr>:nth-child(odd){
    background-color: #000;
}
caption{
    font-size: 2rem;
    font-weight: bold;
    font-style: italic;
    text-transform: uppercase;
    margin: 70px 0;
}
@media screen and (max-width:768px){
    caption{
        font-size: x-large;
        margin: 50px 0;
    }
    tr,td{
        padding: 5px;
        font-size: 11px;
    }
}