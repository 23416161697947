.Programs{
    display: flex;
    flex-direction: column;
    gap: 5rem;
    padding: 0 2rem;
    margin-top: 2rem;
}
.programs-header{
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 3rem;
    justify-content: center;
    color: #fff;
    text-transform: uppercase;
    font-style: italic;
    text-align: center;
    
}
.program-catagories{
    display: flex;
    gap: 1rem;
}
.category{
    display: flex;
    flex-direction: column;
    background-color: var(--darkGrey);
    padding: 2rem;
    gap: 1rem;
    color: #fff;
    justify-content: space-between;
    transition: .5s;
}
.category:hover{
    position: relative;
    bottom: 5px;
    box-shadow: 5px 4px 5px 8px #000;

}
.category>:nth-child(1){
    width: 2rem;
    height: 2rem;
    fill: #FFF;
    padding: 8px 20rem 5px 10px;
    border-radius: 2rem;
    background: var(--gradientChoice);
}
.category>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
    
}
.category>:nth-child(3){
    font-size: 0.9rem;
    line-height: 25px;

}
.join-now{
    display: flex;
    align-items: center;
    gap: 2rem;
}
.join-now>img{
    width: 1rem;
}
.category:hover{
    background:var(--gradientChoice);
}
@media screen and (max-width:768px){
    .programs-header{
        flex-direction: column;
        gap: 1rem;
        font-size: 12px;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }
    .program-catagories{
        flex-direction: column;
    }
    .category>:nth-child(1){
        padding: 8px 7.8rem 5px 10px;
    }
}