/* make variables */
:root {
  --lightgray: #D9D9D9;
  --orange:#f82525;
  --gray: #5f5f5f;
  --red: #df632a;
  --darkGrey: #464D53;
  --caloryCard: #656565;
  --planCard: linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%);
  --appColor:  #000;
  --lightBlue:#62b4cf;
  --gradientChoice:linear-gradient(to right, #e43a15, #e65245);
}

.App {
  background-image: url(./assets/background.jpg);
  mix-blend-mode: overlay;
  background-color: var(--appColor);
  display: flex;
  flex-direction: column;
  gap: 6rem;
  overflow: hidden;
}
::-webkit-scrollbar{
  display: none;
}

.stroke-text{
  color:transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}
.stroke-text-at{
  color: var(--orange);
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}

.btn{
  background-color: #fff;
  padding: 0.5rem;
  font-weight: bold;
  border: 4px solid transparent;
  transition: 300ms all;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn>a{
  color: #000;
  text-decoration: none;
}
.btn:hover{
  cursor: pointer;
}
.sec_btn{
  background-color: transparent;
  padding: 0.5rem;
  font-weight: lighter;
  border: 4px solid transparent;
  transition: 300ms all;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sec_btn>a{
  color: #fff;
  text-decoration: none;
}