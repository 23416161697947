.Footer-container{
    position: relative;
}
.Footer-container>hr{
    border: 1px solid var(--lightgray);

}
.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;
}
.social-links{
    display: flex;
    gap: 4rem;
}
.social-links>a:nth-child(1)>img{
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
}
.social-links>a:nth-child(2)>img{
    width: 8rem;
    height: 6rem;
    cursor: pointer;
    margin-top: -28px;
}
.logo-f>img{
    width: 90px;
    height: 90px;
}
@media screen and (max-width:768px){
    .footer{
        gap: 2rem;
    }
    .social-links>a:nth-child(2)>img{
        width: 5rem;
        height: 3rem;
        margin-top:-5px;
    }   

}
