.Chooses{
    padding: 0 2rem;
    display: flex;
    gap: 2rem;
}
.left-c{
    flex: 1 1;
    display: flex;
    flex-direction: column;
}
.left-c>span{
    color: #fff;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
}
.left-c>.change{
    font-size: 1.5rem;
    font-weight: lighter;
    font-style: italic;
    color: var(--lightgray);
}
.left-c>span>span{
    color: var(--orange);
}
.left-c>img{
    width: 50px;
    margin-top: 4.5rem;
}

.right-c{
    flex: 1 1;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
    grid-gap: 1rem;
    margin-right: -2rem;
}
.right-c>img{
    object-fit: cover;
    
}
.right-c>:nth-child(1){
    width: 20rem;
    grid-row: 1/3;
    height: 17rem;
}
.right-c>:nth-child(2){
    width: 25rem;
    height: 17rem;
    grid-column: 2/4;

}
.right-c>:nth-child(3){
    width: 25rem;
    height: 17rem;
    grid-column: 2/3;
    grid-row: 2;
}
.right-c>:nth-child(4){
    width: 20rem;
    height: 17rem;
    grid-row: 2;
    grid-column: 3/4;
}
@media screen and (max-width:768px){
    .Chooses{
        padding: 0 2rem;
        flex-direction: column;
    }
    .right-c{
        flex: 1 1;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        gap: 1rem;
        grid-auto-rows: 1fr;
    }
    .right-c>img{
        object-fit: cover;

    }
    .right-c>:nth-child(1){
        width: 10rem;
        grid-row: 1/3;
        height: 17rem;
        margin-left: -25px;
    }
    .right-c>:nth-child(2){
        width: 10rem;
        height: 8.3rem;
        grid-column: 2/4;
        margin-left: -10px;
    
    }
    .right-c>:nth-child(3){
        width: 10rem;
        height: 8.3rem;
        grid-column: 2/3;
        grid-row: 2;
        margin-top: -148px;
        margin-left: -10px;
    }
    .right-c>:nth-child(4){
        width: 19rem;
        height: 17rem;
        grid-row: 2;
        grid-column: 3/4;
        margin-left: -343px;
    }
    .left-c{
        flex: 1 1;
        gap: 1rem;
        display: flex;
        flex-direction: column;
        font-size: 3rem;
        color: #fff;
        font-style: italic;
        font-weight: bold;
    }
    .left-c>li{
        list-style: none;
        font-size: 1rem;
        font-style: normal;
        font-weight: lighter;
        text-align: justify;
        margin-top: 5px;
    }
    .left-c>img{
        width: 1.5rem;
        margin-top: 20px;
    }
}