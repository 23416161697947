.plans-container{
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    position: relative;

}
.programs-headers{
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 3rem;
    justify-content: center;
    color: #fff;
    text-transform: uppercase;
    font-style: italic;
    text-align: center;
    margin-right: 5rem;
}

.plans{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5rem;
}
.plan>a>button{
    border: none;
    background: transparent;
}
.plan{
    display: flex;
    flex-direction: column;
    background: var(--caloryCard);
    color: #fff;
    gap: 2rem;
    padding:1.5rem;
    width: 20rem;
}
.plan:nth-child(2){
    background: var(--planCard);
    transform: scale(1.1);
}
.plan>svg{
    fill: var(--lightBlue);
    width: 2rem;
    height: 2rem;
}
.plan>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}
.plan>:nth-child(3){
    font-size: 3rem;
    font-weight: bold;
}
.plan>:nth-child(5){
    font-size: 0.8rem;
}
.features{
    display: flex;
    flex-direction: column;
    gap: 1rem;

}
.feature{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.feature>img{
    width: 1rem;
}
.plans>:nth-child(2)>svg{
    fill: #fff;

}
.plans>:nth-child(2)>a>button{
    color: var(--lightBlue);
}
a{
    text-decoration: none;
    color: #fff;
}
.btn>button{
    font-weight: bold;
    cursor: pointer;
}
@media screen and (max-width:768px){
    .plans-container{
        margin-top: -15px;
    }
    .plans{
        flex-direction: column;
        
    }
    .plan{
        width: 15rem;
    }
    .plans>:nth-child(2){
        transform: none;
    }
    .programs-headers{
        flex-direction: column;
        gap: 1rem;
        margin-left: 80px;
        font-size: 12px;
        align-items: center;
        justify-content: center;
        margin-right: 5rem;
        
    }
    .programs-headers>span{
        font-size:10px;
        margin-top: -15px;
    }

}