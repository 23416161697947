.header{
    display: flex;
    justify-content: space-between;
}
.logo{
    width: 5rem;
    height: 5rem;   
}
.header-menu{
    list-style:none;
    display: flex;
    gap:2rem;
    color: #fff;
    margin-top: 23px;
    flex: 1;
    text-align: center;
}
.header-menu>li{
    margin-left: 20px;
    
}
.header-menu>li{
    color: #fff;
    text-decoration: none;

}
.header-menu>li:hover{
    color:var(--red);
    cursor:pointer;
}
@media screen and (max-width:768px){
    .header>:nth-child(2){
        position: fixed;
        right: 2rem;
        z-index: 99;
    }
    .header-menu{
        flex-direction: column;
        background-color: var(--appColor);
        padding: 2rem;
    }
    .header>.logo{
        margin-left: -20px;
    }
    .logo{
        width: 4.5rem;
        height: 4.5rem;   
    }
}