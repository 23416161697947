.Abouts{
    padding: 0 5rem;
    display: flex;
    grid-template: 2rem;
}
.left-r{
    flex: 1 1;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 1rem;
    grid-auto-rows: 1fr;
}
.left-r>img{
    object-fit: cover;
}
.left-r>:nth-child(1){
    width: 20rem;
    grid-row: 1/3;
    height: 38rem;
}
.left-r>:nth-child(2){
    width: 30rem;
    height: 22rem;
    grid-column: 2/4;

}
.left-r>:nth-child(3){
    width: 16rem;
    height: 15rem;
    grid-column: 2/3;
    grid-row: 2;
}
.left-r>:nth-child(4){
    width: 13rem;
    height: 15rem;
    grid-row: 2;
    grid-column: 3/4;
    margin-left: -10px;
}
.right-r{
    flex: 1 1;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    font-size: 3rem;
    color: #fff;
    font-style: italic;
    font-weight: bold;
}
.right-r>li{
    list-style: none;
    font-size: 1rem;
    font-style: normal;
    font-weight: lighter;
    text-align: justify;
    margin-top: 5px;
}
@media screen and (max-width:768px){
    .Abouts{
        padding: 0 2rem;
        flex-direction: column;
    }
    .left-r{
        grid-auto-rows: auto;
        overflow: hidden;
    }
    .left-r>:nth-child(1){
        width: 7rem;
        height: 17rem;
    }
    .left-r>:nth-child(2){
        width: 15rem;
        height: 10rem;
    }
    .left-r>:nth-child(3){
        width: 8rem;
        height: 95px;
    }
    .left-r>:nth-child(4){
        width: 0rem;
        height: 6rem;
    }
    .right-r{
        margin-top: 3rem;
        
    }
    .right-r>span{
        font-size: x-large;
    }
    .right-r>li{
        font-size: 0.7rem;
    }
}